











































































































































































































































































































































































































































































































import Vue from 'vue'
import { mapState } from 'vuex'
import { stockStatuses } from '@/formHelpers'
import { ApiResponse, Rate, Sale } from '@/interfaces'

interface Rubrique {
  children?: Rubrique
  label: string
  value: null | string
}

export default Vue.extend({
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },
    page: {
      type: String,
      required: false,
      default: null,
    },
    contact: {
      type: String,
      required: false,
      default: null,
    },
    saleId: {
      type: Number,
      required: false,
      default: null,
    },
    query: {
      type: Object,
      required: false,
      default: null,
    },
    editPrice: {
      type: Boolean,
      required: false,
      default: false,
    },
    itemToEdit: {
      type: Object,
      required: false,
      default: null,
    },
    requisitionId: {
      type: Number,
      required: false,
      default: null,
    },
    displayField: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      busy: false,
      viewportWidth: 0,
      formModel: {
        weaponBook: null as null | string,
        vehicule: null as null | string,
        policeBook: null as null | string,
        adagp: null as null | string,
        rubriqueKey: [null] as unknown,
        highlight: null as null | string,
        sale: null as null | string,
        stockStatus: null as null | string,
        dateIn: null as null | string,
        dateOut: null as null | string,
        itemNumber: null as null | string,
        itemSubNumber: null as null | string,
        estimationLow: null as null | string,
        estimationHigh: null as null | string,
        startingPrice: null as null | string,
        reservePrice: null as null | string,
        reserveNetPrice: null as null | string,
        storageArea: null as null | string,
        tvaRate: null as null | string,
        description: null as null | string,
      },
      deleteWeaponBook: false,
      deleteItemNumber: false,
      deleteItemSubNumber: false,
      deleteVehicule: false,
      deletePolicebook: false,
      deleteAdagp: false,
      deleteRubrique: false,
      deleteHighlight: false,
      deleteSale: false,
      deleteDateIn: false,
      deleteDateOut: false,
      deleteStorageArea: false,
      deleteEstimationLow: false,
      deleteEstimationHigh: false,
      deleteStartingPrice: false,
      deleteReservePrice: false,
      deleteReserveNetPrice: false,
      deleteTvaRate: false,
      deleteDescription: false,
      rubriques: [] as Rubrique[],
      sales: [] as Record<string, string | null>[],
      stockStatuses,
      vatRates: [] as Rate[],
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    itemToEdit(newVal) {
      if (newVal) {
        this.formModel = {
          ...newVal,
        }

        if (!this.displayField) {
          this.$nextTick(() => {
            document.getElementById('itemNumberInput')?.focus()
          })
        }
      }
    },
  },
  mounted() {
    if (this.user) {
      this.rubriques = Object.assign([], this.user.client.config.rubriques)
      this.rubriques.push({
        value: null,
        label: 'Pas de changement',
      })
      this.vatRates = this.user.client.structures[0]?.taxRate?.rates ?? []
    }
    if (this.itemToEdit) {
      this.formModel = {
        ...this.itemToEdit,
      }
    }

    this.viewportWidth = window.innerWidth

    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.viewportWidth = window.innerWidth
    },
    closeModal(updateCheckboxValue = true) {
      this.formModel = {
        weaponBook: null,
        vehicule: null,
        policeBook: null,
        adagp: null,
        rubriqueKey: [null],
        highlight: null,
        sale: null,
        stockStatus: null as null | string,
        dateIn: null as null | string,
        dateOut: null as null | string,
        storageArea: null as null | string,
        itemNumber: null as null | string,
        itemSubNumber: null as null | string,
        estimationLow: null as null | string,
        estimationHigh: null as null | string,
        reservePrice: null as null | string,
        reserveNetPrice: null as null | string,
        startingPrice: null as null | string,
        tvaRate: null as null | string,
        description: null as null | string,
      }

      this.sales = []

      this.deleteWeaponBook = false
      this.deleteVehicule = false
      this.deletePolicebook = false
      this.deleteAdagp = false
      this.deleteRubrique = false
      this.deleteHighlight = false
      this.deleteSale = false
      this.deleteDateIn = false
      this.deleteDateOut = false
      this.deleteItemNumber = false
      this.deleteItemSubNumber = false
      this.deleteEstimationLow = false
      this.deleteEstimationHigh = false
      this.deleteStartingPrice = false
      this.deleteReservePrice = false
      this.deleteReserveNetPrice = false
      this.deleteStorageArea = false
      this.deleteTvaRate = false
      this.deleteDescription = false

      this.$emit('closeEditModal', updateCheckboxValue)
    },
    submit() {
      let data = {
        ...this.formModel,
      }

      if (this.deleteWeaponBook == true) {
        data.weaponBook = 'NULL'
      }

      if (this.deleteVehicule == true) {
        data.vehicule = 'NULL'
      }

      if (this.deletePolicebook == true) {
        data.policeBook = 'NULL'
      }

      if (this.deleteAdagp == true) {
        data.adagp = 'NULL'
      }

      if (this.deleteRubrique == true) {
        data.rubriqueKey = 'NULL'
      } else {
        if (Array.isArray(this.formModel.rubriqueKey)) {
          data.rubriqueKey = this.formModel.rubriqueKey[this.formModel.rubriqueKey.length - 1]
        } else {
          data.rubriqueKey = this.formModel.rubriqueKey
        }
      }

      if (this.deleteDateIn == true) {
        data.dateIn = 'NULL'
      }

      if (this.deleteDateOut == true) {
        data.dateOut = 'NULL'
      }

      if (this.deleteHighlight == true) {
        data.highlight = 'NULL'
      }

      if (this.deleteSale == true) {
        data.sale = 'NULL'
      }

      if (this.deleteItemNumber == true) {
        data.itemNumber = 'NULL'
      }

      if (this.deleteItemSubNumber == true) {
        data.itemSubNumber = 'NULL'
      }

      if (this.deleteStorageArea == true) {
        data.storageArea = 'NULL'
      }

      if (this.deleteDescription == true) {
        data.description = 'NULL'
      }

      if (this.deleteStartingPrice == true) {
        data.startingPrice = 'NULL'
      }

      if (this.deleteReservePrice == true) {
        data.reservePrice = 'NULL'
      }

      if (this.deleteReserveNetPrice == true) {
        data.reserveNetPrice = 'NULL'
      }

      if (this.deleteEstimationLow == true) {
        data.estimationLow = 'NULL'
      }

      if (this.deleteEstimationHigh == true) {
        data.estimationHigh = 'NULL'
      }

      const params = {
        ...this.query,
        items: this.editPrice ? [this.itemToEdit.id] : this.items,
        data,
      }
      if (this.contact) {
        params.contactId = this.contact
      }
      if (this.page === 'saleItemsList') {
        params.saleId = this.saleId
      }

      this.busy = true
      this.$api
        .put(`/requisitionitems/edit`, params)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          if (apiResponse.data.pagination) {
            this.$emit('updateItems', apiResponse.data.items)
            this.$emit('setPaginationData', apiResponse.data.pagination)
          } else {
            this.$emit('updateItems', apiResponse.data)
          }

          if (apiResponse.data.length > 0) {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })
          }

          this.closeModal()
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
        .finally(() => {
          this.busy = false
          if (this.page === 'boughtItems' || this.page === 'stockItemsList') {
            this.$emit('uncheckAll')
          }
        })
    },
    loadSales(isVisible: boolean) {
      if (isVisible) {
        this.sales = []

        this.$api.get('/sales?type=simple').then((response) => {
          const apiResponse = response.data as ApiResponse

          apiResponse.data.forEach((sale: Sale) => {
            if (sale.status && sale.status !== 'comptabilise') {
              this.sales.push({
                label: this.getSaleTitle(sale),
                value: sale?.id?.toString() ?? null,
              })
            }
          })
        })
      }
    },
    getSaleTitle(sale: Sale): string {
      let saleTitle = ''
      if (sale?.title) {
        saleTitle += ' ' + sale?.title
      }
      if (sale?.startDate) {
        const date = Date.parse(sale?.startDate)
        if (date) {
          saleTitle += ' - ' + new Intl.DateTimeFormat('fr-FR').format(date)
        }
      }
      return saleTitle !== '' ? saleTitle : '-'
    },
    searchExistingStorageArea(queryString: string, cb: CallableFunction) {
      let results = [] as Record<string, string>[]
      const storageAreas = [] as Record<string, string>[]

      this.$api
        .get('/storage-areas')
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          apiResponse.data.forEach((storageArea: string) => {
            storageAreas.push({ value: storageArea })
          })
          results = queryString ? storageAreas.filter(this.createFilter(queryString)) : storageAreas
        })
        .finally(() => {
          cb(results)
        })
    },
    createFilter(queryString: string) {
      return (str: Record<string, string>) => {
        return str.value?.toLowerCase().indexOf(queryString.toLowerCase()) === 0 ?? null
      }
    },
    filterCategory(node: Record<string, string>, keyword: string) {
      return node.label.toLowerCase().includes(keyword.toLowerCase())
    },
    isStatusAllowed(status: string) {
      if (this.user?.roles.includes('ROLE_USER2')) {
        if (
          status == 'selling' ||
          status == 'sold' ||
          status == 'removed' ||
          status == 'not_shown' ||
          status == 'given_back' ||
          status == 'amicably_sold' ||
          status == 'unfulfilled'
        ) {
          return false
        }
      }
      return true
    },
  },
})
